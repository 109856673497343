.bg-auth-card {
    flex-shrink: 0;
    border-radius: 16px;
    background-color: #fff;
}

.page-sub-title {
    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.page-sub-desc {
    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
}

.form-inputs label {
    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;

}

.form-inputs input,
.form-inputs select,
.form-inputs select option {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #F3F2FD;
    margin-bottom: 15px;
    margin-top: 8px;

    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form-inputs input:disabled {
    background: #e6e5ed;
}

.form-inputs button {
    width: 100%;
    height: 50px;
    flex-shrink: 0;

    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
}


@media screen and (max-width:767px) {

    .form-inputs input,
    .form-inputs select,
    .form-inputs select option,
    .form-inputs button {
        height: 50px;
    }

    .form-inputs button {
        height: 50px;
    }
}
.form-inputs-phone{
    width: 100% !important;
    height: 50px !important;
    flex-shrink: 0 !important;
    border-radius: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.10) !important;
    background: #F3F2FD !important;
    margin-bottom: 15px !important;
    margin-top: 8px !important;
    color: var(--T, #313131) !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}
.div-otp {
    width: 40%;
    height: 350px;
    flex-shrink: 0;
    border-radius: 11px;
    background: #D6DBFB;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 992px) {
    .div-otp {
        width: 100%;
        height: 350px;
        padding: 0px 25px;
    }
}

@media only screen and (min-width:993px) and (max-width: 1200px) {
    .div-otp {
        width: 50%;
    }
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.datePickerClass {
    width: 100% !important;
    height: 50px !important;
    flex-shrink: 0 !important;
    border-radius: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.10) !important;
    background: #F3F2FD !important;
    margin-bottom: 15px !important;
    margin-top: 8px !important;
    color: var(--T, #313131) !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

.react-datepicker {
    background-color: #F3F2FD !important;
    border: 1px solid #aeaeae !important;
    border-radius: .3rem !important;
    color: #000 !important;
    display: inline-block !important;
    font-family: "Poppins", sans-serif !important;
    font-size: .8rem !important;
    position: relative !important;
}

.react-datepicker input,
.react-datepicker select,
.react-datepicker select, 
.react-datepicker option {
    width: auto !important;
    height: 40px !important;
    font-size: 14px !important;
    flex-shrink: 0 !important;
    border-radius: 16px !important;
    border: 1px solid rgba(0, 0, 0, 0.10) !important;
    background: #F3F2FD !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    color: var(--T, #313131) !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
}

/* .react-datepicker input:disabled {
    background: #e6e5ed !important;
}

.react-datepicker button {
    width: 100% !important;
    height: 30px !important;
    flex-shrink: 0 !important;
    color: #FFF !important;
    font-family: "Poppins", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    margin-top: 8px !important;
} */