.plan-header-1 {
    background: #26BC00;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.plan-footer-1 {
    display: flex;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    background: #26BC00;
    cursor: pointer;
    transition: 0.2s background ease-in-out;

    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.plan-footer-1:hover {
    background: #209902;
}

.disabled-plan-1 {
    background: #7cb46d !important;
    cursor: not-allowed !important;
}

.plan-header-2 {
    background: #FCB527;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.plan-footer-2 {
    display: flex;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    background: #FCB527;
    cursor: pointer;
    transition: 0.2s background ease-in-out;

    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.plan-footer-disabled {
    display: flex;
    padding: 20px 0px;
    justify-content: center;
    align-items: center;
    background: #d39417;
    cursor: not-allowed;
    transition: 0.2s background ease-in-out;

    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.plan-footer-2:hover {
    background: #cf9114;
}