.box-plan {
    width: 90%;
    height: 70px;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    background: #FFF8EB;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 18px;
    padding-right: 18px;

    color: #313131;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn-views button {
    border-radius: 48px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10);
    height: 40px;
    width: 150px;
    flex-shrink: 0;
    color: #5946CE;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.btn-views button:last-child {
    border-radius: 48px;
    background: #5A45CE;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10);
    height: 40px;
    width: 150px;
    flex-shrink: 0;
    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
@media screen and (max-width:350px) {
    .btn-views button{
        width: auto !important;
        padding-left: 15px;
        padding-right: 15px;
    }
}