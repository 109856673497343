.bg-sub-home {
    /* background: rgb(255, 255, 255);
    background: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
    background: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#304ccf", GradientType=1); */
}

.home-title {
    color: #5A45CE;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.home-sub-title {
    font-family: "Poppins", sans-serif;
    color: rgba(7, 23, 23, 0.52);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}