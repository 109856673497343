.react-flow__node {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.10) !important;
    background: #FFF;
    flex-shrink: 0;
}

.react-flow__edge-path {
    stroke-width: 1px !important;
    stroke: #304CCF !important;
    stroke-dasharray: 5, 5;
    animation: dash 1s linear infinite;
}
.react-flow__attribution{
    display: none;
}
/* .react-flow{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
} */
@keyframes dash {
    to {
        stroke-dashoffset: -10;
    }
}