.header-nav-logo {
    font-family: "Poppins", sans-serif !important;
    /* color: #FFF; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.custom-border-active {
    border-bottom: 6px solid white;
    /* border-radius: 3px 3px 3px 3px; */
}

.custom-transform-border {
    transition: 0.15s border ease-in-out;
}

.custom-nav-align ul {
    align-items: center;
}

@media screen and (max-width: 767px) {
    .custom-nav-align ul {
        align-items: flex-start;
    }
}

.custom-btn-login button {
    padding-left: 25px !important;
}