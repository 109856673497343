.custom-bg-banner {
    min-height: 81vh;
    width: 100%;
    background-image: url(/public/assets/banner.svg);
    background-color: #5A45CE;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 20px;
}

.custom-banner-texts div h1 {
    color: #DBD7FD;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: stretch;
}

.custom-banner-texts div h3 {
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    align-self: stretch;
}

.custom-banner-texts div p {
    color: #FFF;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.custom-btn-banner {
    color: #5A45CE;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    flex-shrink: 0;
    border-radius: 48px;
    background: #FFF;
}
.custom-btn-banner span{
    color: #5A45CE;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
}