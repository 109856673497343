.bg-default {
    /* background: rgb(249, 247, 255);
    background: -moz-linear-gradient(90deg, rgb(249, 247, 255) 0%, rgb(249, 247, 255) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
    background: -webkit-linear-gradient(90deg, rgb(249, 247, 255) 0%, rgb(249, 247, 255) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
    background: linear-gradient(90deg, rgb(249, 247, 255) 0%, rgb(249, 247, 255) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#304ccf", GradientType=1); */
}

.btn-theme-color {
    border-radius: 48px;
    background: #5A45CE;
    transition: 0.2s background ease-in-out;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10);
}

.btn-theme-color:hover {
    background: #4834b9;
}
.btn-theme-color:disabled {
    background: #8273db;
    cursor: progress;
}

.font-pop {
    font-family: "Poppins", sans-serif !important;
    color: var(--T, #313131);
    font-style: normal;
    line-height: normal;
}
.font-pop-colorLess {
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    line-height: normal;
}

.common-transition {
    transition: 0.2s all ease-in-out;
}

.loaders {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid darkblue;
    /* Blue */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 2s linear infinite;
    margin: auto;
}

.wait {
    color: darkblue;
    margin-top: 1rem;
    padding-bottom: 1.5rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* --------------------------------- */

.swal-overlay--show-modal .swal-modal {
    background: #fff !important;
    color: #000 !important;
}

.swal-title {
    color: #000;
}

button.swal-button.swal-button--confirm {
    border-radius: 48px;
    font-family: "Poppins", sans-serif !important;
    padding-left: 30px;
    padding-right: 30px;
    background: #5A45CE;
    transition: 0.2s background ease-in-out;
    box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.10);
    ;
}

button.swal-button.swal-button--confirm:hover {
    background: #4834b9;
}

.swal-footer {
    margin-top: 0px;
    text-align: center;
}

.swal-modal {
    background: #fff !important;
}

.swal-icon--success:after {
    background: none !important;
}

.swal-icon--success:before {
    background: none !important;
}

.swal-icon--success__hide-corners {
    background: none !important;
}

.swal-text {
    text-align: center;
    color: #000;
    font-weight: normal;
}

.css-46bh2p-MuiCardContent-root {
    padding: 0px !important;
}

.css-1qw96cp {
    padding: 0px !important;
}

/* swal */
.swal-title {
    color: #000 !important;
    font-weight: normal;
}

.swal-text {
    color: #000 !important;
}

.modal_class_success {
    background-color: #fff !important;
    color: #000 !important;
}

.modal_class_success .swal-icon::before {
    background-color: transparent !important;
}

.modal_class_success .swal-icon::after {
    background-color: transparent !important;
}

.swal-icon--success__hide-corners {
    display: none !important;
}

.closeD {
    text-align: right;
}

.closeBtn {
    display: flex;
    align-items: center;
    justify-content: end;
}

.iconClose {
    position: absolute !important;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: none !important;
    border: none !important;
    font-size: 25px !important;

}

.iconCloseR {
    position: absolute;
    top: 2px;
    right: 5px;
    width: 40px;
    height: 40px;
    background: none !important;
    border: none !important;
    font-size: 25px !important;

}

/* connect wallet button  */

.connectWallet {
    width: 170px;
    height: 40px;
    background: linear-gradient(92.18deg, #0D86D7 -2.82%, #D24BB0 99.39%);
    box-shadow: 4px 4px 20px #7A66C2;
    border-radius: 8px;
    border: transparent !important;
}

.connectWallet:hover {

    background: linear-gradient(92.18deg, #0675be -2.82%, #b63696 99.39%);
}

.css-imxc6v-MuiBadge-badge {
    padding: 0px!important;
}

.link_hash {
    color: #000 !important;
    text-decoration: none !important;
}

/* central title  */
.titleOfCentral {
    font-style: normal;
    font-size: 34px;
    color: black;
    font-weight: 800;
}

@media only screen and (max-width: 650px) {
    .titleOfCentral {
        font-style: normal;
        font-size: 28px;
        color: black;
        font-weight: 800;
    }

    .handleSmTitleCentral {
        width: 400px;
        font-size: 24px;
        position: relative;
        left: -110px;
    }
}

/* th, td{
    width: 170px !important;
} */