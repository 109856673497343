@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: rgb(249, 247, 255);
  background: -moz-linear-gradient(90deg, rgb(249, 247, 255) 0%, rgb(249, 247, 255) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
  background: -webkit-linear-gradient(90deg, rgb(249, 247, 255) 0%, rgb(249, 247, 255) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
  background: linear-gradient(90deg, rgb(249, 247, 255) 0%, rgb(249, 247, 255) 62%, rgba(48, 76, 207, 0.3925945378151261) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#304ccf", GradientType=1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-family: "Poppins", sans-serif !important;
}

img {
  max-width: 100%;
  height: auto;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.t-width {
  min-width: '100px' !important;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  .t-width {
    min-width: '900px' !important;
    overflow: auto;
  }
}