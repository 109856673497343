.tabGeneology {
    flex-shrink: 0;
    border-radius: 32px;
    background: rgba(211, 212, 255, 0.53);
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.acTab {
    border-radius: 32px;
    background: #5A45CE;
    color: #FFF;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.deAcTab {
    border-radius: 32px;
    background: transparent;
    color: #263238;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
}

.dHomeUpperCard {
    display: inline-flex;
    padding: 0px 20px;
    height: 100px;
    width: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    border-radius: 16px;
    background: #FFF;
    cursor: pointer;
}

.dHomeUpperCard:hover {
    transform: scale(1.1);
}

.dHomeUpperCard h5 {
    color: var(--T, #313131);
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
}

.dHomeUpperCard p {
    color: #5A45CE;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media screen and (min-width:1700px) {
    .dHomeUpperCard {
        width: 350px;
    }
}

@media screen and (max-width:891px) {
    .dHomeUpperCard {
        height: 90px;
        width: 250px;
    }
}

@media screen and (max-width:791px) {
    .dHomeUpperCard {
        height: 90px;
        width: 100%;
    }
}

.status-card-green {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    background: #C8FDE8;

    color: #027763;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
}

.date-card {
    color: #263238;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.date-card-2 {
    color: black;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    line-height: normal;
    opacity: 0.75;
}

.status-card-red {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    background: #FFE3E2;

    color: #F10D0D;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
}

.status-card-orange {
    display: inline-flex;
    padding: 5px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    background: #FCB527;

    color: #3e2c09;
    font-family: "Poppins", sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;
}

label {
    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.75;

}

.dProfile-form-inputs input,
.form-inputs select,
.form-inputs select option {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #F3F2FD;
    margin-bottom: 15px;
    margin-top: 8px;

    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.special-custom {
    width: 100%;
    height: 50px;
    flex-shrink: 0;
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #F3F2FD;
    margin-bottom: 15px;
    margin-top: 8px;

    color: var(--T, #313131);
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.dProfile-form-inputs button {
    width: 100%;
    height: 50px;
    flex-shrink: 0;

    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;
}


@media screen and (max-width:767px) {

    .dProfile-form-inputs input,
    .form-inputs select,
    .form-inputs select option,
    .form-inputs button {
        height: 50px;
    }

    .special-custom {
        height: 50px;
    }

    .dProfile-form-inputs button {
        height: 50px;
    }
}

.size-div {
    /* width: 80%; */
}

.upload-div {
    display: flex;
    height: 200px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: 3px dashed #024F83;
    margin-top: 25px;
}

.upload-div:hover {
    border: 3px dashed #272a2c;
}

.table-cell-width {
    min-width: 170px;
}

.table-fixed-custom {
    /* table-layout: fixed; */
    min-width: 900px;
}

@media only screen and (max-width: 1200px) {
    .table-fixed-custom {
        /* table-layout: fixed; */
        min-width: 200px;
    }
}