.bg-footer {
    background: #5A45CE;
}

.footerLinks li {
    color: #FFF;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
}

.footerLinks li:last-child {
    margin-bottom: 0px !important;
}