.stake-title {
    color: #1E1E1E;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 128.023%;
    /* 40.967px */
}

.stake-sub-title {
    color: #575757;
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 178%;
    /* 33.82px */
}